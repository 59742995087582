import message from '../assets/puzzles/message.png';
import PuzzleElement from "../components/PuzzleElement";

const PuzzleMessage = () => {

  return (
    <PuzzleElement name="Message" image={message} desktopBackground="/background/message_desktop.png" mobileBackground="/background/message_mobile.png" piecesCount={5} />
  )
}

export default PuzzleMessage;