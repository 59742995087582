import pluie from '../assets/puzzles/pluie.png';
import PuzzleElement from "../components/PuzzleElement";

const PuzzlePluie = () => {

  return (
    <PuzzleElement name="Pluie" image={pluie} desktopBackground="/background/pluie_desktop.png" mobileBackground="/background/pluie_mobile.png" />
  )
}

export default PuzzlePluie;