const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  GRAY: '#CBCBCB',
  BLUE: '#3D8FF2',
  GREEN: '#5D8B81',
  PINK: '#FFD1D1',
  YELLOW: '#F9E95D',
  BEIGE: '#FCEED0',
}

export { COLORS };