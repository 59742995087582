import bonnet from '../assets/puzzles/bonnet.png';
import PuzzleElement from "../components/PuzzleElement";

const PuzzleBonnet = () => {

  return (
    <PuzzleElement name="Bonnet" image={bonnet} desktopBackground="/background/bonnet_desktop.png" mobileBackground="/background/bonnet_mobile.png" piecesCount={3} />
  )
}

export default PuzzleBonnet;