import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/style/reset.scss';
import './index.scss';
import './assets/style/fonts.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import PuzzleOuaf from './pages/PuzzleOuaf';
import PuzzlePluie from './pages/PuzzlePluie';
import Page404 from './pages/Page404';
import PuzzleMessage from './pages/PuzzleMessage';
import PuzzleBonnet from './pages/PuzzleBonnet';
import PuzzleChaussettes from './pages/PuzzleChaussettes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="ouaf" element={<PuzzleOuaf />} />
        <Route path="pluie" element={<PuzzlePluie />} />
        <Route path="message" element={<PuzzleMessage />} />
        <Route path="bonnet" element={<PuzzleBonnet />} />
        <Route path="chaussettes" element={<PuzzleChaussettes />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
