import ouaf from '../assets/puzzles/ouaf.png';
import PuzzleElement from "../components/PuzzleElement";

const PuzzleOuaf = () => {

  return (
    <PuzzleElement name="Ouaf" image={ouaf} desktopBackground="/background/ouaf_desktop.png" mobileBackground="/background/ouaf_mobile.png" />
  )
}

export default PuzzleOuaf;