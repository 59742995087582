import React, { useEffect, useState } from 'react';

function TextEffect({ initialText, className }) {
  const [letters, setLetters] = useState([]);

  useEffect(() => {
    if (initialText) {
      const textWithoutBreaks = initialText.replace(/<br>/g, '+');
      const lettersArray = textWithoutBreaks.split('').map((char, index) => (
        char === '+' ? <br key={index} /> : <span key={index} className="contact__color">{char}</span>
      ));

      setLetters(lettersArray);
    }
  }, [initialText]);

  return (
    <div id="textEffect" className={className}>
      {letters}
    </div>
  );
}

export default TextEffect;