import WindowUI from "../components/WindowUI";
import Base from "../components/Base";
import style from '../assets/style/pages/home.module.scss'
import layout from '../assets/style/layout.module.scss'
import grid from '../assets/svg/home_background.svg'
import { ReactComponent as LogoSVG } from '../assets/svg/logo.svg';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import React from "react";
import TextEffect from "../components/TextEffect";

const Home = () => {
  useGSAP(() => {
    gsap.to(".window", {
      opacity: 1,
      scale: 1,
      stagger: 0.05,
      duration: 0.3,
      ease: "power2.out",
    });
  });

  const initialText = `Saviez-vous qu'avoir une chaussette dans sa poche porte beaucoup plus bonheur qu'un trèfle à 4 feuilles ? En partant de cette information 100% fiable, Paulette vous propose un puzzle pour reconstituer un fond d'écran très chau… sette évidemment.`;

  return (
    <div className={style.container} style={{backgroundImage: `url(${grid})`}}>
      <Base style={{justifyContent: 'center'}}>
        <div className={layout.row} style={{position: 'relative'}}>
          <div className={layout.col4}>
            <div className={style.window}>
              <div className={style.tab}>
                <div className={style.dot}></div>
                <div className={style.dot}></div>
                <div className={style.dot}></div>
              </div>

              <div className={style.content}>
                <h2 className={style.title}>À vous de jouer !</h2>

                <TextEffect initialText={initialText} className={style.text} />

                <div className={style.divider}></div>

                <div className={style.flex}>
                  <p className={style.year}>Et bonne année hein !</p>

                  <LogoSVG />
                </div>
              </div>
            </div>
          </div>
          <div className={layout.col1}></div>

          <div className={layout.col7}>
            <div className={style.windowContainer}>
              <div className={style.windowSize}>
                <WindowUI slug="message" />
                <WindowUI slug="ouaf" />
              </div>

              <div className={style.windowSize}>
                <WindowUI slug="pluie" />
                <WindowUI slug="bonnet" />
              </div>

              <div className={style.over}>
                <WindowUI slug="chaussettes" />
              </div>
            </div>
          </div>
        </div>
      </Base>
    </div>
  )
}

export default Home;