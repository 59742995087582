import Base from "../components/Base";
import { ReactComponent as ErrorSVG } from '../assets/svg/404.svg';
import style from '../assets/style/pages/error.module.scss';
import { Link } from "react-router-dom";
import { ReactComponent as LogoSVG } from '../assets/svg/logo.svg';

const Page404 = () => {

  return (
    <Base style={{justifyContent: 'space-between'}}>
      <div>
        <Link to={`/`}>
          <LogoSVG className={style.logo} />
        </Link>
      </div>

      <div style={{textAlign: 'center'}}>
        <ErrorSVG className={style.image} />
        <h2 className={style.title}>Erreur 404.</h2>
        <p>Mon chou, la page que tu cherches, elle n’existe pas.</p>
      </div>

      <div className={style.footer}>
        <p>©2024 Vas-y Paulette.</p>
      </div>
    </Base>
  )
}

export default Page404;