import { Link } from "react-router-dom";
import { Canvas, Outline, painters, generators } from "../headbreaker";
import { useEffect, useState } from "react";
import Base from "./Base";
import style from "../assets/style/pages/puzzle.module.scss";
import layout from "../assets/style/layout.module.scss";
import { ReactComponent as LogoSVG } from "../assets/svg/logo.svg";
import { ReactComponent as BravoSVG } from "../assets/svg/bravo.svg";
import { ReactComponent as ClapSVG } from "../assets/svg/clap.svg";
import useResizeObserver from "@react-hook/resize-observer";
import classNames from "classnames";

const PuzzleElement = ({
  name,
  image,
  desktopBackground,
  mobileBackground,
  piecesCount = 4,
}) => {
  const [isWinning, setIsWinning] = useState(false);
  const [puzzle, setPuzzle] = useState(null);
  const [currentSize, setCurrentSize] = useState(0);
  const [originalSize, setOriginalSize] = useState(0);

  const getMeasures = () => {
    let pieceSize = 280 / piecesCount;
    let height = 400;
    let width = 400;

    if (window.matchMedia("(min-width: 768px)").matches) {
      pieceSize = 485 / piecesCount;
      height = 600;
      width = 700;
    }

    if (window.matchMedia("(min-width: 1180px)").matches) {
      pieceSize = 485 / piecesCount;
      height = 600;
      width = 900;
    }

    setCurrentSize(pieceSize);
    return { pieceSize, height, width };
  };

  useEffect(() => {
    const measures = getMeasures();
    setOriginalSize(measures.pieceSize);

    let background = new Image();
    background.src = image;
    background.onload = () => {
      const canvas = new Canvas("puzzle", {
        width: measures.width,
        height: measures.height,
        pieceSize: measures.pieceSize,
        proximity: 20,
        strokeWidth: 0.5,
        strokeColor: "#808080",
        image: background,
        preventOffstageDrag: true,
        fixed: true,
        outline: new Outline.Rounded(),
        painter: new painters.Konva(),
      });
      setPuzzle(canvas);

      canvas.adjustImagesToPuzzleHeight();
      canvas.autogenerate({
        horizontalPiecesCount: piecesCount,
        verticalPiecesCount: piecesCount,
        insertsGenerator: generators.random,
      });

    
      canvas.shufflePaulette({
        horizontalPiecesCount: piecesCount,
        verticalPiecesCount: piecesCount,
      });
      canvas.draw();
      canvas.attachSolvedValidator();
      canvas.onValid(() => {
        if (canvas.puzzle.isValid()) {
          setIsWinning(true);
        }
      });
    };
  }, []);

  const handleOnClick = () => {
    setIsWinning(false);
    puzzle.shufflePaulette({
      horizontalPiecesCount: piecesCount,
      verticalPiecesCount: piecesCount,
    });
    puzzle.renderPuzzle(puzzle.puzzle);
    puzzle.redraw();
  };

  const handleOnResize = () => {
    let oldSize = currentSize;

    if (puzzle) {
      const measures = getMeasures();

      puzzle.resize(measures.width, measures.height);
      if (oldSize !== measures.pieceSize) {
        if (originalSize === measures.pieceSize) {
          puzzle.scale(1);
        } else {
          originalSize > measures.pieceSize
            ? puzzle.scale(280 / piecesCount / (485 / piecesCount))
            : puzzle.scale(485 / piecesCount / (280 / piecesCount));
        }
      }

      puzzle.redraw();
    }
  };

  useResizeObserver(document.querySelector("#root"), handleOnResize);

  return (
    <Base style={{ justifyContent: "space-between" }}>
      <div>
        <Link to={`/`}>
          <LogoSVG className={style.logo} />
        </Link>
      </div>

      <div className={layout.row}>
        <div className={layout.col3}>
          <div className={style.content}>
            <div>
              <Link to={-1} className={style.link}>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse cx="15" cy="15" rx="15" ry="15" fill="black" />
                  <path
                    d="M6 14.4985L12.2534 8L13.5982 9.38847L9.63028 13.5239H23V15.4761H9.63028L13.5982 19.6085L12.2534 21L6 14.4985Z"
                    fill="white"
                  />
                </svg>
                <span>Retour</span>
              </Link>

              <button
                className={classNames(style.link, style.restart)}
                onClick={handleOnClick}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse cx="15" cy="15" rx="15" ry="15" fill="black" />
                  <path
                    d="M18.6163 11.1684C19.9607 12.5128 20.4594 14.4209 20.1125 16.1556L19.05 15.0931L17.5104 16.6327L21.2617 20.3839L25.013 16.6327L23.4734 15.0931L22.2808 16.2857C22.6928 13.9222 21.9773 11.4286 20.1558 9.62884C17.5755 7.04849 13.629 6.70155 10.6584 8.54466L12.263 10.1493C14.3229 9.08675 16.9033 9.43369 18.6163 11.1684Z"
                    fill="white"
                  />
                  <path
                    d="M18.8115 21.555L17.2069 19.9504C15.147 20.9912 12.5666 20.6443 10.8319 18.9313C9.48754 17.5869 8.98881 15.6787 9.33575 13.944L10.3983 15.0065L11.9378 13.467L8.18652 9.7157L4.43524 13.467L5.97478 15.0065L7.16738 13.8139C6.77708 16.1774 7.49264 18.6711 9.31407 20.4708C11.8944 23.0512 15.8409 23.4198 18.8115 21.555Z"
                    fill="white"
                  />
                </svg>
                <span>Recommencer</span>
              </button>
            </div>

            {isWinning && (
              <div>
                <h3 className={style.title}>Un souvenir ?</h3>
                <Link
                  className={style.download}
                  to={desktopBackground}
                  download={`Desktop_${name}_VYP`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Desktop</span>
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="100" height="100" rx="50" fill="black" />
                    <path
                      d="M50.509 62.128L37.958 49.582L40.6411 46.882L48.6363 54.856V28H52.3998V54.838L60.395 46.864L63.078 49.564L50.527 62.11L50.509 62.128ZM32.6999 73C31.4034 73 30.3049 72.532 29.3866 71.614C28.4682 70.696 28 69.598 28 68.302V59.32H31.7635V68.302C31.7635 68.536 31.8535 68.752 32.0516 68.932C32.2497 69.13 32.4478 69.22 32.6819 69.22H68.3181C68.5522 69.22 68.7683 69.13 68.9484 68.932C69.1465 68.734 69.2365 68.536 69.2365 68.302V59.32H73V68.302C73 69.598 72.5318 70.696 71.6134 71.614C70.6951 72.532 69.5966 73 68.3001 73H32.6639H32.6999Z"
                      fill="white"
                    />
                  </svg>
                </Link>
                <Link
                  className={style.download}
                  to={mobileBackground}
                  download={`Mobile_${name}_VYP`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Mobile</span>
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="100" height="100" rx="50" fill="black" />
                    <path
                      d="M50.509 62.128L37.958 49.582L40.6411 46.882L48.6363 54.856V28H52.3998V54.838L60.395 46.864L63.078 49.564L50.527 62.11L50.509 62.128ZM32.6999 73C31.4034 73 30.3049 72.532 29.3866 71.614C28.4682 70.696 28 69.598 28 68.302V59.32H31.7635V68.302C31.7635 68.536 31.8535 68.752 32.0516 68.932C32.2497 69.13 32.4478 69.22 32.6819 69.22H68.3181C68.5522 69.22 68.7683 69.13 68.9484 68.932C69.1465 68.734 69.2365 68.536 69.2365 68.302V59.32H73V68.302C73 69.598 72.5318 70.696 71.6134 71.614C70.6951 72.532 69.5966 73 68.3001 73H32.6639H32.6999Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className={layout.col9}>
          <div style={{ position: "relative" }}>
            <div id="puzzle" className={style.puzzle}></div>

            <img className={style.model} src={image} alt="" />

            <div className={style.clap}>
              <div style={{ position: "relative" }}>
                {isWinning && <ClapSVG />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.footer}>
        <p>©2024 Vas-y Paulette.</p>
      </div>

      {isWinning && (
        <div className={style.bravo}>
          <BravoSVG />
        </div>
      )}
    </Base>
  );
};

export default PuzzleElement;
