import chaussettes from '../assets/puzzles/chaussettes.png';
import PuzzleElement from "../components/PuzzleElement";

const PuzzleChaussettes = () => {

  return (
    <PuzzleElement name="Chaussettes" image={chaussettes} desktopBackground="/background/chaussettes_desktop.png" mobileBackground="/background/chaussettes_mobile.png" piecesCount={3} />
  )
}

export default PuzzleChaussettes;