import { Link } from "react-router-dom";
import style from '../assets/style/components/window.module.scss'
import { ReactComponent as MessageCoverSVG } from '../assets/svg/message_cover.svg';
import { ReactComponent as PluieCoverSVG } from '../assets/svg/pluie_cover.svg';
import { ReactComponent as ChaussettesCoverSVG } from '../assets/svg/chaussettes_cover.svg';
import { ReactComponent as OuafCoverSVG } from '../assets/svg/ouaf_cover.svg';
import { ReactComponent as BonnetCoverSVG } from '../assets/svg/bonnet_cover.svg';
import { COLORS } from "../constants";
import { useEffect, useState } from "react";
import classNames from "classnames";

const WindowUI = ({ slug }) => {
  const [windowHeight, setWindowHeight] = useState(320);
  const [backgroundColor, setBackgroundColor] = useState(COLORS.WHITE);
  const [firstDotColor, setFirstDotColor] = useState(COLORS.PINK);
  const [secondDotColor, setSecondDotColor] = useState(COLORS.BLUE);
  const [thirdDotColor, setThirdDotColor] = useState(COLORS.BEIGE);
  const [illuColor, setIlluColor] = useState(COLORS.GREEN);
  const [IlluSVG, setIlluSVG] = useState(MessageCoverSVG);
  const [zIndex, setZIndex] = useState(1);
  const [marginBottom, setMarginBottom] = useState(0);

  useEffect(() => {
    switch (slug) {
      case 'message':
        setWindowHeight(320);
        setBackgroundColor(COLORS.WHITE);
        setFirstDotColor(COLORS.PINK);
        setSecondDotColor(COLORS.BLUE);
        setThirdDotColor(COLORS.BEIGE);
        setIlluColor(COLORS.GREEN);
        setIlluSVG(MessageCoverSVG);
        setZIndex(1);
        setMarginBottom(60);
        break;

      case 'pluie':
        setWindowHeight(360);
        setBackgroundColor(COLORS.GREEN);
        setFirstDotColor(COLORS.PINK);
        setSecondDotColor(COLORS.BLUE);
        setThirdDotColor(COLORS.BEIGE);
        setIlluColor(COLORS.BEIGE);
        setIlluSVG(PluieCoverSVG);
        setZIndex(1);
        setMarginBottom(70);
        break;

      case 'chaussettes':
        setWindowHeight(360);
        setBackgroundColor(COLORS.BLUE);
        setFirstDotColor(COLORS.PINK);
        setSecondDotColor(COLORS.GREEN);
        setThirdDotColor(COLORS.WHITE);
        setIlluColor(COLORS.PINK);
        setIlluSVG(ChaussettesCoverSVG);
        setZIndex(2);
        setMarginBottom(0);
        break;

      case 'ouaf':
        setWindowHeight(310);
        setBackgroundColor(COLORS.BEIGE);
        setFirstDotColor(COLORS.PINK);
        setSecondDotColor(COLORS.WHITE);
        setThirdDotColor(COLORS.BLUE);
        setIlluColor(COLORS.GREEN);
        setIlluSVG(OuafCoverSVG);
        setZIndex(3);
        setMarginBottom(0);
        break;

      case 'bonnet':
        setWindowHeight(210);
        setBackgroundColor(COLORS.WHITE);
        setFirstDotColor(COLORS.PINK);
        setSecondDotColor(COLORS.BEIGE);
        setThirdDotColor(COLORS.BLUE);
        setIlluColor(COLORS.PINK);
        setIlluSVG(BonnetCoverSVG);
        setZIndex(3);
        setMarginBottom(0);
        break;
    
      default:
        break;
    }
  }, []);

  return (
    <div className={classNames(style.window, 'window')} style={{backgroundColor: backgroundColor, height: windowHeight, zIndex: zIndex, marginBottom: marginBottom}}>
      <div className={style.tab}>
        <div className={style.dot} style={{backgroundColor: firstDotColor}}></div>
        <div className={style.dot} style={{backgroundColor: secondDotColor}}></div>
        <div className={style.dot} style={{backgroundColor: thirdDotColor}}></div>
      </div>

      <Link to={slug} className={style.link}>
        <div className={style.illu} style={{backgroundColor: illuColor}}>
          <div className={style.hover}>
            <div className={style.filter}></div>

            <svg width="99" height="115" viewBox="0 0 99 115" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M99 57.5L0.749995 114.225L0.75 0.775333L99 57.5Z" fill="white"/>
            </svg>
          </div>

          <IlluSVG />
        </div>
      </Link>
    </div>
  )
}

export default WindowUI;