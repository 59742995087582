import styles from '../assets/style/components/base.module.scss'

const Base = ({ children, style }) => {
  return (
    <div className={styles.container} style={style}>
      { children }
    </div>
  )
}

export default Base;